export const EVENTS = {
  NEW_RENTAL: 'new_rental',
  NEW_RESERVATION: 'new_reservation',
  NEW_PAYMENT: 'new_payment',
  ACCOUNT: 'account',
  CONTACT: 'contact',
  MOVE_OUT: 'move_out',
  AUTO_PAY: 'auto_pay',
  PASSWORD: 'password'
}

export const AMENITIES = [
  { name: '24-hours', label: '24/7 Video Recording' },
  { name: 'acessible', label: '24/7 Access' },
  { name: 'air', label: 'Air-conditioned Units' },
  { name: 'autopay', label: 'Autopay Available' },
  { name: 'boat-park', label: 'Boat Parking' },
  // { name: 'cart', label: 'Credit Card Accepted' },
  { name: 'cash', label: 'Cash Accepted' },
  { name: 'chat', label: 'Live Chat' },
  { name: 'check', label: 'Check Accepted' },
  { name: 'clean-fac', label: 'Clean Facilities' },
  { name: 'clean-unit', label: 'Clean Units' },
  { name: 'climate', label: 'Climate Controlled' },
  { name: 'competitive', label: 'Competitive Rates' },
  { name: 'convenient', label: 'Convenient' },
  { name: 'cover-parking', label: 'Covered Parking' },
  { name: 'credit-card', label: 'Credit Card Accepted' },
  // { name: 'customer-exc', label: 'Excellent Customer Service' },
  { name: 'customer-serv', label: 'Excellent Customer Service' },
  { name: 'driveup', label: 'Drive-up Units' },
  { name: 'elevator', label: 'Elevator Access' },
  { name: 'fence', label: 'Fence and Lighted' },
  { name: 'friendly', label: 'Friendly Staff' },
  { name: 'fully-boat-park', label: 'Fully Enclosed Boat Parking' },
  { name: 'fully-rv-parking', label: 'Fully Enclosed RV Parking' },
  { name: 'gate-access', label: 'Electronic Gate Access' },
  { name: 'gate-pincode', label: 'Gate with Pin-code Access' },
  { name: 'ground-floor', label: 'Ground Floor Access' },
  { name: 'interior-entry', label: 'Interior Entry' },
  { name: 'keypad', label: 'Keypad Access' },
  { name: 'local', label: 'Locally Owned' },
  { name: 'manager', label: 'On-site Manager' },
  { name: 'medical', label: 'Medical Storage' },
  { name: 'moving', label: 'Moving Supplies' },
  { name: 'office', label: 'Office Suites' },
  { name: 'online-bill', label: 'Online Bill Pay' },
  // { name: 'online-pay', label: 'Convenient' },
  { name: 'parking', label: 'Parking' },
  { name: 'pest', label: 'Pest Control' },
  { name: 'rollup-door', label: 'Roll Up Door' },
  // { name: 'rv', label: 'Convenient' },
  { name: 'rv-parking', label: 'RV Parking' },
  // { name: 'scooter', label: 'Convenient' },
  { name: 'secure', label: 'Secure' },
  { name: 'security-camera', label: 'Security Camera System' },
  { name: 'solar', label: 'Solar Powered' },
  { name: 'surve-camera', label: 'Surveillance Cameras' },
  { name: 'temperature', label: 'Temperature Control Units' },
  // { name: 'truck', label: 'Convenient' },
  { name: 'truck-semi', label: 'Semi Truck Access' },
  { name: 'video', label: 'Video cameras on site' },
  // { name: 'video-rec', label: 'Convenient' },
  // { name: 'weather', label: 'Convenient' },
  { name: 'wellit', label: 'Well-lit Premises' }
]

// This name is used as a placeholder for the Steave product name.
// It will need to be updated when an official name for the product exists.
export const STEAVE_NAME = 'Steave'

export const STEAVE_STATUS_ENUM = {
  'Live': "LIVE",
  'live': "LIVE",
  'Onboarding': "ONBOARDING",
  'onboarding': "ONBOARDING",
  'Coming Soon': "COMING_SOON",
  'coming soon': "COMING_SOON",
  'Coming soon': "COMING_SOON",
}

export const STEAVE_TO_INSIGHTS_STATUS_ENUM = {
  'LIVE': "Live",
  'Live': "Live",
  'ONBOARDING': "Onboarding",
  'Onboarding': "Onboarding",
  'COMING_SOON': "Coming Soon",
  'Coming Soon': "Coming Soon",
}