import { config } from "@/config"
import axios from "axios"

import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a website API wrapper around axios.
 */
class SteaveApi extends BaseApi {
    constructor () {
        super()
        this.type = "steave"
    }

    buildUrl(accountId) {
        let url = this.baseDomain
        url += `/${ this.type }/${ accountId }`
        return url
    }

    updateCache(params) {
        try {
            let url = this.baseDomain
            // Attach path and location
            url += `/${ this.type }/${ params.accountId }/update-unit-cache-all`
            if (!params.body) return this.api.post(url, { timeout: 30000 })
            else return this.api.post(url, params.body, { timeout: 30000 })
        } catch (error) {
            console.error(error)
            Vue.rollbar.error(error)
            return error
        }
    }
}

export default new SteaveApi()


/**
 * Create an axios instance with the necessary headers to interact with the Steave API.
 * 
 * @todo
 * - Not all accounts will work correctly with the firebase auth token. We need to verify
 *   that the user has an account in both the Firebase and Steave systems.
 */
export const usePugFetch = async (url, opts = {}, version = 1) => {
    const idToken = await firebase.auth().currentUser.getIdToken()
    const headers = {
        "Authorization": `Bearer ${ idToken }`,
        "Content-Type": `application/vnd.storagepug.backend.v${ version }+json`,
        Accept: `application/vnd.storagepug.backend.v${ version }+json`
    }

    return axios.request({
        url,
        baseURL: config.steave_api_url,
        headers,
        ...opts
    })
}

/**
 * Global function to update Steave location data.
 * 
 * @todo
 * - Should we silently update and fail if it doesn't work?
 * - Check all instances and remove await if not needed
 * - We may not need to wait for the steave update to finish
 * 
 * @param { String } locationId 
 * @param { any } body 
 * @returns Promise<AxiosResponse<any>>
 */
export const updateSteaveLocation = (locationId, body) => {
    return usePugFetch(`/location/${ locationId }/no-insights`, {
        method: "PUT",
        data: body
    })
}

/**
 * Map the Steave location data to the Insights format. We can ignore the
 * hours and amenities because Steave will pull these from Insights.
 * 
 * @param { any } location 
 * @returns Object
 */
export const getSteaveLocationMap = (steaveLocation, insightsLocation) => {
    const locObj = {}

    // Map the address information
    locObj.address = {
        city: steaveLocation?.city,
        state_province: steaveLocation?.stateProvince,
        street_1: steaveLocation?.streetAddress,
        street_2: steaveLocation?.streetAddress2,
        postal: steaveLocation?.postal,
        country: steaveLocation?.country,
        lon: steaveLocation?.lon,
        lat: steaveLocation?.lat,
    }
    locObj.name = steaveLocation?.name
    locObj.email = steaveLocation?.email
    locObj.time_zone = steaveLocation?.timezone
    locObj.google_review_link = steaveLocation?.googleReviewLink
    locObj.facebook_review_link = steaveLocation?.facebookReviewLink
    locObj.yelp_review_link = steaveLocation?.yelpReviewLink
    locObj.phone_number = steaveLocation?.phone

    // Map the location media
    locObj.main_logo = steaveLocation?.logo
    locObj.main_image = steaveLocation?.mainImage
    // We need to remap the media id, so it does not duplicate media objects in the Insights database
    locObj.media = steaveLocation?.media.map(m => ({ ...m.media, id: m.media.sysid })) || []

    // Map url slug
    // Fallback to the insights url slug if the steave url slug is not set
    locObj.url_slug = steaveLocation?.urlSlug || insightsLocation?.url_slug

    // Map the social media links from Steave
    locObj.yelp = steaveLocation?.yelp
    locObj.google = steaveLocation?.google
    locObj.facebook = steaveLocation?.facebook
    locObj.twitter = steaveLocation?.twitter
    locObj.instagram = steaveLocation?.instagram
    locObj.linkedin = steaveLocation?.linkedin

    // Map the success messages from Steave
    // The emails information is set in the Steave Location's Contact settings
    locObj.rental_success_message = steaveLocation?.emails?.newRental ?? null
    locObj.reservation_success_message = steaveLocation?.emails?.newReservation ?? null
    locObj.move_out_confirmation_message = steaveLocation?.emails?.moveOut ?? null
    locObj.auto_pay_terms = steaveLocation?.emails?.autopay ?? null

    return locObj
}